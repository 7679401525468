import React from 'react';
import { StaticQuery,graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Image from "gatsby-image";
import Header from '../../components/header';
import { Carousel } from "react-responsive-carousel";
import HeaderName from "../../svg/donice.svg";

const Section02 = ({dataPack, lang, headerD= dataPack.allJson.edges[0].node.headerData[4]}) => (
  <section className={`${headerD.headerSection}`} id={`${headerD.headerLink}`}>

    <Header
      siteTitle={headerD.headerName[lang]}
      imgBg={headerD.headerImage.childImageSharp.fluid}
      imgLogo={headerD.headerLogo.childImageSharp.fluid}
      imgBgAlt={headerD.headerImgAlt[lang]}
      imgLogoAlt={headerD.headerLogoAlt[lang]}
      />

        <Container fluid >
              <Row className="screenRow textScreen">
            <Col md={1} className="leftBar d-none d-md-flex flex-wrap align-content-center">
              <HeaderName />
            </Col>
            <Col lg={4} md={3} sm={2} className="leftBarFirst d-none d-sm-block">

            </Col>
            <Col lg={4} md={3} sm={2} className="centerBar d-none d-sm-block">
            </Col>
            <Col lg={4} md={6} sm={8} xs={12} className="col-right paragraph-horizontal align-content-center">

              <div className="text-horizontal"
                dangerouslySetInnerHTML={{ __html: dataPack.allMarkdownRemark.edges[0].node.excerpt}}
              />

            </Col>
          </Row>

          <Row noGutters  className="screen">
              <Carousel
                className="d-flex carouse-bg slide-2 col"
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                showArrows={false}
                centerMode
                infiniteLoop autoPlay
              >
              {dataPack.allMarkdownRemark.edges[0].node.frontmatter.lechuza.map(items=>(
               <div className="my-slide">
                 <Image
                    alt={items.alt}
                    fluid={items.pic.childImageSharp.fluid}
                 />
               </div>
               ))}

              </Carousel>
          </Row>

        </Container>
  </section>

)

export default props =>(
<StaticQuery query={graphql`
  query b2bQueryPL{
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/b2b/pl/" } }
    ) {
      edges {
        node {
          excerpt(format: HTML, pruneLength: 1000)
          frontmatter {
            lechuza{
              alt
              pic{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allJson(sort: {fields: headerData}, limit: 1) {
      edges {
        node {
          headerData{
              headerName
              headerLink
              headerSection
              headerImgAlt
              headerLogoAlt
              headerImage{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              headerLogo{
                childImageSharp{
                  fluid(quality: 100){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`}
render={data=><Section02 dataPack={data}{...props} />}
/>
)
